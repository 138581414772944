
<div>
	<v-app-bar app color="primary" style="color: white" dark elevation="0">
		<v-app-bar-nav-icon color="white" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
		<v-toolbar-title>
			<span>PowerText</span>
		</v-toolbar-title>

		<v-spacer></v-spacer>

		<v-menu bottom min-width="200px" rounded offset-y>
			<template v-slot:activator="{ on }">
				<v-btn icon v-on="on">
					<v-icon>mdi-apps</v-icon>
				</v-btn>
			</template>
			<v-card style="padding-top: 20px">
				<h3 style="margin-left: 20px">Switch to</h3>
				<v-list dense>
					<v-list-item :href="app.link" color="primary" v-for="app in powersuiteApps" :key="app.name">
						<v-list-item-action>
							<v-icon>mdi-apps</v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>{{
								app.name
							}}
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-card>
		</v-menu>
	</v-app-bar>
	<v-navigation-drawer v-model="drawer" app floating style="background-color: #fafafa">
		<div class="placeholder" style="
				background-color: var(--v-primary-base);
				height: 64px;
				width: 100%;
				border-right: 1px solid #f1f1f1;
				position: absolute;
				top: 0;
				left: 0;
			"></div>

		<v-menu bottom min-width="200px" rounded offset-y>
			<template v-slot:activator="{ on }">
				<v-btn icon v-on="on" style="
						margin-top: 25px;
						margin-bottom: 15px;
						width: 72px;
						height: 72px;
						margin-left: 92px;
					">
					<v-avatar color="secondary" size="72">
						<span v-if="initials" class="white--text headline">{{ initials }}</span>
					</v-avatar>
				</v-btn>
			</template>
			<v-card>
				<v-list-item-content class="justify-center">
					<div class="mx-auto text-center">
						<v-avatar color="secondary">
							<span v-if="initials" class="white--text headline">{{ initials }}</span>
						</v-avatar>
						<h3 style="margin-top: 10px" v-if="user">
							{{ user.username }}
						</h3>
						<p v-if="user" class="caption mt-1">
							{{ user.organisation }}
						</p>
						<v-divider class="my-3"></v-divider>
						<v-btn color="primary" dark class="mb-2" @click="handleLogout()">
							Logout
						</v-btn>
					</div>
				</v-list-item-content>
			</v-card>
		</v-menu>

		<v-list dense no-action v-for="menuItem in menuItems" :key="menuItem.title">
			<v-list-group v-if="menuItem.children" v-model="menuItem.active" @click="collapse(menuItem)"
				@click.stop="goto(menuItem.link)">
				<template v-slot:activator>
					<v-list-item-icon>
						<v-icon>{{ menuItem.icon }}</v-icon>
					</v-list-item-icon>
					<v-list-item-title>{{
						menuItem.title
					}}
					</v-list-item-title>
				</template>
				<v-list-item v-for="child in menuItem.children" :key="child.title" :to="child.link" :class="child.link === $route.path
					? 'v-list-item--active'
					: ''
					" class="ml-8" link>
					<v-list-item-icon>
						<v-icon>{{ child.icon }}</v-icon>
					</v-list-item-icon>
					<v-list-item-title>
						{{ child.title }}
					</v-list-item-title>
				</v-list-item>
			</v-list-group>

			<v-list-item v-else @click="collapse(menuItem)" :to="menuItem.link">
				<v-list-item-icon>
					<v-icon>{{ menuItem.icon }}</v-icon>
				</v-list-item-icon>
				<v-list-item-title>
					{{ menuItem.title }}
				</v-list-item-title>
			</v-list-item>
		</v-list>
	</v-navigation-drawer>
</div>
