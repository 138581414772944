export default {
	state: {
		id: "",
		username: "",
		organisation: ""
	},
	mutations: {
		setUser(state, user) {
			if (!user) return;
			const { id, username, organisation } = user || {};
			state.id = id;
			state.username = username;
			state.organisation = organisation;
		}
	},
	actions: {
		updateUser({ commit }, user) {
			commit("setUser", user);
		}
	},
	getters: {
		getUser(state) {
			return {
				id: state.id,
				username: state.username,
				organisation: state.organisation
			};
		}
	}
};
