<template>
	<div></div>
</template>

<script>
import Intercom from '@intercom/messenger-js-sdk';

export default {
	watch: {
		user(newUser, oldUser) {
			this.checkIntercomChatbot();
		}
	},
	computed: {
		user() {
			return this.$store.getters.getUser;
		}
	},
	methods: {
		checkIntercomChatbot() {
			const user = this.$store.getters.getUser;

			if (!user) {
				return;
			}

			Intercom({
				app_id: 'enwry1os',
				user_id: `${user.id}`,
				name: user.organisation,
			});
		}
	},
	mounted() {
		this.checkIntercomChatbot();
	}
};
</script>
